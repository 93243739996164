<template>
  <div class="compounded-interest-simulator-wrapper">
    <b-container class="main-gradient light" fluid>
      <b-row class="main-gradient-header-wrapper" no-gutters>
        <b-col no-gutters>
          <b-breadcrumb :items="breadcrumbItems" class="breadcrumb-light"></b-breadcrumb>
          <h1 class="text-left">{{ $t(translationPath + 'title') }}</h1>
        </b-col>
      </b-row>

      <b-container fluid>
        <slot name="header">
        </slot>
        <b-row class="amount-chart-wrapper-row pt-3 pb-5">
          <b-col no-gutters>
            <div class="chart-wrapper chart-wrapper-border-top amount-chart-wrapper pb-4" style="" ref="chart-wrapper">
              <bar-chart v-if="loaded"
                :chart-data="chartDataCollection"
                :currency="currency"
                :currencySymbol="currencySymbol"
                :options="chartOptions"
                :chart-id="'bajschart'"
                :width="100"
                :height="400"
                :css-classes="''"
                :plugins="[]"
              >
              </bar-chart>
              <template v-else>
                <b-alert class="mb-0" show>{{ $t(translationPath + 'no_data') }}</b-alert>
              </template>
          </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>

    <b-container class="px-0">
      <b-row class="main-content-wrapper pb-0" no-gutters>
        <div class="card-container px-0">
            <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto mb-4 mt-4">
              <template v-slot:header>
                <b-row>
                  <b-col lg="6">
                    <h2>{{ $t(translationPath + 'auto_calculation') }}</h2>
                  </b-col>
                  <b-col lg="6" class="text-lg-right">
                    <i18n :path="translationPath + 'heading_amount'" tag="h2">
                      <template slot="amount" v-if="finalAmount">{{ finalAmount | formatAmount(currentCOA.locale,currentCOA.currency, currentCOA.currency_iso,true, 0) }}</template>
                      <template slot="amount" v-else> {{ 0 | formatAmount(currentCOA.locale,currentCOA.currency, currentCOA.currency_iso,true, 0) }}</template>
                      <template slot="year"> {{ params.years }} </template>
                    </i18n>
                  </b-col>
                </b-row>
              </template>

              <b-col cols="12" class="px-0">
                <b-form>
                  <b-row class="px-1">
                    <b-col cols="12" md="6">
                      <b-form-group
                        label-class="FormLabels"
                        label-for="starting_amount"
                        label-cols="12"
                        label-align="left"
                      >
                        <template slot="label">{{ $t(translationPath + 'field_starting_amount_label') }}</template>
                        <b-col sm="12" md="5" xl="3" class="float-left mb-2 pl-0 pr-0">
                          <currency-input
                            ref="CurrencyInputStartingAmount"
                            id="starting_amount"
                            v-model="params.startingAmount"
                            :precision="0"
                            :class="$v.params.startingAmount.$error ? 'is-invalid' : ''"
                            :state="$v.params.startingAmount.$dirty ? !$v.params.startingAmount.$error : null"
                            @input="$v.params.startingAmount.$touch()"
                          />
                        </b-col>
                        <b-col sm="12" md="7" xl="9" class="float-left pl-0 pb-2">
                          <b-form-input
                            id="starting_amount_slider"
                            class="slider ml-2"
                            type="range"
                            min="0"
                            max="999999"
                            step="100"
                            v-model="params.startingAmount"
                          ></b-form-input>
                        </b-col>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label-class="FormLabels"
                        label-for="amount_per_month"
                        label-cols="12"
                        label-align="left"
                      >
                        <template slot="label">{{ $t(translationPath + 'field_amount_per_month_label') }}</template>
                        <b-col sm="12" md="5" xl="3" class="float-left mb-2 pl-0 pr-0">
                          <currency-input
                            ref="CurrencyInputAmountPerMonth"
                            id="amount_per_month"
                            v-model="params.amountPerMonth"
                            :precision="0"
                            :class="$v.params.amountPerMonth.$error ? 'is-invalid' : ''"
                            :state="$v.params.amountPerMonth.$dirty ? !$v.params.amountPerMonth.$error : null"
                            @input="$v.params.amountPerMonth.$touch()"
                          />
                        </b-col>
                        <b-col sm="12" md="7" xl="9" class="float-left pl-0 pb-2">
                          <b-form-input
                            id="amount_per_month_slider"
                            class="slider ml-2"
                            type="range"
                            min="0"
                            max="99999"
                            step="100"
                            v-model="params.amountPerMonth"
                          ></b-form-input>
                        </b-col>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="px-1">
                    <b-col cols="12" md="4">
                      <b-form-group
                        label-class="FormLabels"
                        label-for="years"
                        label-cols="12"
                        label-align="left"
                      >
                        <template slot="label">{{ $t(translationPath + 'field_years_label') }}</template>
                        <b-col sm="12" md="5" xl="3" class="float-left mb-2 pl-0 pr-0">
                          <b-form-input
                            id="years"
                            type="number"
                            min="0"
                            max="100"
                            placeholder="25"
                            v-model="params.years"
                            :state="$v.params.years.$dirty ? !$v.params.years.$error : null"
                            @input="$v.params.years.$touch()"
                          ></b-form-input>
                        </b-col>
                        <b-col sm="12" md="7" xl="9" class="float-left pl-0 pb-2">
                          <b-form-input
                            id="years_slider"
                            class="slider ml-2"
                            type="range"
                            min="0"
                            max="100"
                            step="1"
                            v-model="params.years"
                          ></b-form-input>
                        </b-col>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                      <b-form-group
                        label-class="FormLabels"
                        label-for="interest"
                        label-cols="12"
                        label-align="left"
                      >
                        <template slot="label">{{ $t(translationPath + 'field_interest_label') }}</template>
                        <percentage-input
                            inputId="interest"
                            :precision="1"
                            v-model="params.interest"
                            slider
                            sliderPosition="right"
                            :sliderXl="9"
                            :sliderMd="8"
                            :max="100"
                            @input="$v.params.interest.$touch()"
                        ></percentage-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                      <b-form-group
                        label-class="FormLabels"
                        label-for="tax-type"
                        label-cols="12"
                        label-align="left"
                      >
                        <template slot="label">{{ $t(translationPath + 'field_tax_type_label') }}</template>
                          <b-form-select
                            id="tax-type"
                            v-model="params.taxType"
                            :options = "[{ text: $t(translationPath + 'none'), value: null }]"
                          ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-col>
            </b-card>
        </div>
      </b-row>

      <b-row class="main-content-wrapper pb-0" no-gutters>
        <div class="card-container px-0">
            <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto mb-0 mt-4">
              <template v-slot:header>
                <b-row>
                  <b-col lg="6">
                    <h2>{{$t(translationPath + 'table_heading')}}</h2>
                  </b-col>
                </b-row>
              </template>

              <b-table class="spirecta-simple-table depth-1 mb-0" show-empty hover responsive striped
                stacked="md"
                :items="tableItems"
                :fields="fieldValues"
                :busy="busyState"
              >
                <!--loader-->
                <template v-slot:table-busy>
                  <loader/>
                </template>
                <!--loader ends here-->
              </b-table>
            </b-card>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Loader from '@/components/common/Loader'
import { required, integer, between, minValue } from 'vuelidate/lib/validators'
import formatAmount from '@/assets/filters/formatAmount'
import _ from 'lodash'
import BarChart from '@/components/chartjs/BarChart'
import titleMixins from '@/mixins/title'
import PercentageInput from '@/components/common/PercentageInput'
import CurrencyInput from '@/components/common/CurrencyInput'
import useComponentCurrencyInputMixin from '@/mixins/useComponentCurrencyInput'

export default {
  name: 'CompoundedInterestSimulator',
  mixins: [titleMixins, useComponentCurrencyInputMixin],
  components: { BarChart, Loader, PercentageInput, CurrencyInput },
  filters: { formatAmount },
  validations: {
    params: {
      startingAmount: {
        required,
        minValue: minValue(0)
      },
      amountPerMonth: {
        required,
        minValue: minValue(0)
      },
      years: {
        required,
        integer,
        between: between(0, 100)
      },
      interest: {
        required,
        integer,
        between: between(0, 100)
      }
    },
    form: [
      'params.startingAmount',
      'params.amountPerMonth',
      'params.years',
      'params.interest'
    ]
  },
  data () {
    return {
      loaded: false,
      chartDataCollection: null,
      chartOptions: null,
      currency: 'kr',
      currencySymbol: 'SEK',
      busyState: true,
      tableItems: null,
      finalAmount: 0,
      filter: null,
      translationPath: 'reports.simulators.compounded_interest.',
      params: {
        startingAmount: 10000,
        amountPerMonth: 2000,
        years: 25,
        interest: 8,
        taxType: null
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    breadcrumbItems () {
      return [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports/performance' },
        { text: this.$t('reports._common.simulator_reports'), to: '/reports/simulators' },
        { text: '', active: true }
      ]
    },
    fieldValues () {
      return [
        { key: 'year', label: this.$t(this.translationPath + 'th_year') },
        { key: 'addition', label: this.$t(this.translationPath + 'th_addition'), class: 'text-right' },
        { key: 'networth', label: this.$t(this.translationPath + 'th_networth'), class: 'text-right' }
      ]
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    }
  },
  methods: {
    async loadData () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }

      const data = {
        starting_amount: parseFloat(this.params.startingAmount),
        amount_per_month: parseFloat(this.params.amountPerMonth),
        years: parseInt(this.params.years),
        interest: parseFloat(this.params.interest)
      }
      if (this.params.taxType) {
        data.tax_type = this.params.taxType
      }

      axios.post(`${process.env.VUE_APP_ROOT_API}/financial/planner/compounded-interest`, data)
        .then((response) => {
          this.fillTable(response.data.data)
          this.fillGraph(response.data.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    fillTable (data) {
      this.busyState = true
      let countLength = 0
      const tableItems = []
      for (const prop in data.aData) {
        countLength++
        if (countLength === Object.keys(data.aData).length) {
          this.finalAmount = data.aData[prop].networth
        }
        tableItems.push({
          year: parseInt(prop),
          addition: this.formatAmount(data.aData[prop].addition),
          networth: this.formatAmount(data.aData[prop].networth)
        })
      }
      this.tableItems = tableItems
      this.busyState = false
    },
    fillGraph (data) {
      this.chartOptions = {
        defaultFontFamily: 'Nunito',
        title: {
          display: false,
          text: 'Custom as Title',
          fontColor: '#231f20'
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: '#231f20',
            fontFamily: 'Nunito'
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: true
            },
            ticks: {
              fontColor: '#231f20',
              fontFamily: 'Nunito'
            }
          }],
          yAxes: [{
            display: true,
            gridLines: {
              display: true
            },
            ticks: {
              fontColor: '#231f20',
              fontFamily: 'Nunito',
              callback: (value, index, values) => {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + (this.currentCOA.currency ? ' ' + this.currentCOA.currency : '')
              }
            }
          }]
        },
        pointDot: true,
        pointDotRadius: 5,
        pointDotStrokeWidth: 1,

        onAnimationComplete: null,
        scaleLineColor: 'rgba(0,0,0,.25)',

        responsive: true,
        maintainAspectRatio: false
      }

      const yearKeys = []
      const datasets = [{
        label: this.$t(this.translationPath + 'chart_networth_label'),
        backgroundColor: '#8cccad',
        data: []
      }]
      for (const prop in data.aData) {
        datasets[0].data.push(Math.round(data.aData[prop].networth * 100) / 100)
        yearKeys.push(parseInt(prop))
      }

      this.chartDataCollection = {
        labels: yearKeys,
        datasets: datasets
      }
      this.loaded = true
    },
    formatAmount (val) {
      if (this.currentCOA.locale) {
        val = formatAmount(
          val,
          this.currentCOA.locale,
          this.currentCOA.currency,
          this.currentCOA.currency_iso,
          true,
          2
        )
      }
      return val
    }
  },
  watch: {
    params: {
      deep: true,
      handler () {
        this.rerenderCurrencyInputs()
        this.debounceLoadData()
      }
    },
    currentCOA: {
      deep: true,
      handler () {
        if (this.tableItems) {
          for (const prop in this.tableItems) {
            this.tableItems[prop].addition = this.formatAmount(this.tableItems[prop].addition)
            this.tableItems[prop].networth = this.formatAmount(this.tableItems[prop].networth)
          }
        }
      }
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  },
  created () {
    this.debounceLoadData = _.debounce(this.loadData, 600)
    this.loadData()
  }
}
</script>

<style lang="scss">
.compounded-interest-simulator-wrapper {
  th {
    border-top: none;
  }
  .card-container {
    position: relative;
    padding-left: 35px;
    padding-right: 35px;
    width: 100%;
    top: -52px;
  }
  .FormLabels {
    padding-left: 0px;
    padding-right: 0px;
  }
  .main-content-wrapper {
    padding-top:0;
  }
  .compounded-interest-simulator-heading-wrapper h1 {
    color: #fff;
    font-weight: 400;
  }
  .table-compounded-interests {
    background: #fff;
    padding: 25px 30px;
    margin-top: 25px;
  }
  .numericField {
    input {
      @media screen and (min-width:1440px) and (max-width:2110px) {
        max-width: 65px;
      }
    }
  }
  #compounded-interest_withdrawal_age {
    @media screen and (max-width:768px) {
      max-width: 85%;
    }
    @media screen and (min-width:769px) and (max-width:1439px) {
      max-width: 80%;
    }
  }
  .main-gradient {
    padding-bottom: 45px;
  }
  #start_year, #end_year {
    width: 60px;
  }
  #start_year, #end_year {
    display: inline;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
  #compounded-interest_withdrawal_age {
    float: left;
  }
  .inYearSection {
    float: left;
    height: 100%;
    padding: 0 5px;
    position: relative;
    width: 40%;
    @media screen and (max-width:768px) {
      width: 15%;
    }
    @media screen and (min-width:769px) and (max-width:1439px) {
      width: 20%
    }
    span {
      position: absolute;
      font-weight: 700;
      top: 29%;
    }
  }
  .was-validated .form-control:invalid, .form-control.is-invalid, .form-control.is-valid {
    background-image: none;
    padding-right: initial;
  }
  .form-control.is-valid {
    border-color: #ced4da;
  }
}
</style>
